import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Pagination from '../components/Pagination';
import PreHeader from '../components/PreHeader';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Img from 'gatsby-image';
import SignUpForm from '../components/SignUpForm';

const OnDemandHero = styled.section`
	padding: 0;
	text-align: center;
	padding-top: 1rem;
`;

const OnDemandHeader = styled.h2`
	margin-top: 0;
	font-family: ${cwTheme.s00erif};
	margin-bottom: 0rem;
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const OnDemandMain = styled.section``;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 .5rem;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
		padding: 0 1rem;
	}
`;
const IndexContainer = styled.div`
	flex: 4;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	display: grid;

	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-gap: 1rem;

	@media (min-width: ${cwTheme.smallBreakPoint}) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-bottom: 0;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
`;

const PostCard = styled.div`
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border: 1px solid #e4e4e4;
	border-radius: ${cwTheme.borderRadius};
`;

const OnDemandLinkWrapper = styled.div`
	min-height: 100%;
	background: rgba(255, 255, 255, 1);
	text-decoration: none;
	color: #444;
	display: flex;
	flex-direction: column;

	article {
		padding-bottom: .25rem;
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 10px 5px 10px;
	}
`;

const PostTitle = styled(Link)`
	flex: 1;
	padding-top: 10px;
	padding-bottom:0px;
	line-height: 1.18421053em;
	display: block;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
	display: block;
	font-size: 1em;

	&:hover {
		color: ${cwTheme.blackHover}
	}
	
`;

const OnDemandCategories = styled.section`
	flex: 1;

	display: flex;
	align-items: center;
	flex-direction: column;

	box-sizing: content-box;

	h6 {
		margin: 0;
	}
	margin-bottom: 2rem;
`;

const CategoryList = styled.ul`
	text-decoration: none;
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 2;

	a {
		text-decoration: none;
		color: ${cwTheme.blackHover};
		font-size: 1rem;
		padding: .2rem .5rem;
	}

	a:hover {
		color: ${cwTheme.black};
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
`;

const Category = styled.li``;

const CategoryHome = styled.li`
	a {
		font-weight: 700;
	}
`;

const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
		transition: .3s;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const VideoLength = styled.span`
	color: ${cwTheme.blackHover};
	font-size: .8em;
	margin-bottom: 5px;
`;

function videoLengthCalc(number) {
	var message;
	number > 1 ? (message = number + ' minutes') : (message = number + ' minute');
	return message;
}

const OnDemandCategory = ({ data, pageContext }) => {
	console.log(data);
	console.log(pageContext);

	const posts = data.allContentfulOnDemand.edges;
	const { basePath } = pageContext;
	const allCategories = data.allContentfulOnDemandCategory.edges;

	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title="Wellness On Demand" />

				<OnDemandHero>
					<Container>
						<OnDemandHeader>On Demand</OnDemandHeader>
					</Container>
				</OnDemandHero>

				<OnDemandCategories>
					{/* <h6>All Categories</h6> */}
					<CategoryList>
						<CategoryHome>
							<Link to={'/ondemand'}>All Categories</Link>
						</CategoryHome>
						{allCategories.map(({ node: category }) => (
							<Category key={'maincategory' + category.id}>
								<Link
									to={'/ondemand/category/' + category.slug}
									activeStyle={{ color: cwTheme.black }}
									partiallyActive={true}
								>
									{category.title}
								</Link>
							</Category>
						))}
					</CategoryList>
				</OnDemandCategories>

				<OnDemandMain>
					<InnerWrapper>
						<IndexContainer>
							{posts.map(({ node: post }, i) => (
								<PostCard key={post.id}>
									<OnDemandLinkWrapper>
										<Link to={'/ondemand/' + post.id}>
											<Img backgroundColor={cwTheme.lightBlue} sizes={post.thumbnail.fluid} />
										</Link>

										<article>
											<PostTitle to={'/ondemand/' + post.id}>{post.title}</PostTitle>
											<VideoLength>&#128336; {videoLengthCalc(post.videoLength)}</VideoLength>
											<TagContainer>
												{post.category == null ? (
													''
												) : (
														post.category.map((category) => (
															<Link
																key={'postcategory' + category.id}
																to={'/ondemand/category/' + category.slug}
															>
																{category.title}
															</Link>
														))
													)}
											</TagContainer>
										</article>
									</OnDemandLinkWrapper>
								</PostCard>
							))}
						</IndexContainer>
					</InnerWrapper>
				</OnDemandMain>

				<Pagination context={pageContext} />

				<SignUpForm header={'Get notified when new videos are added!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($skip: Int!, $limit: Int!, $slug: String!) {
		allContentfulOnDemand(
			sort: { fields: [publishDate], order: DESC }
			limit: $limit
			skip: $skip
			filter: { category: { elemMatch: { slug: { eq: $slug } } } }
		) {
			totalCount
			edges {
				node {
					title
					id
					videoLength
					category {
						title
						slug
						id
					}
					publishDate(formatString: "MMMM DD, YYYY")
					description {
						childMarkdownRemark {
							excerpt
							timeToRead
						}
					}
					thumbnail {
						fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
							src
							...GatsbyContentfulFluid_noBase64
						}
					}
				}
			}
		}
		contentfulOnDemandCategory(slug: { eq: $slug }) {
			title
		}
		allContentfulOnDemandCategory(sort: { fields: title, order: ASC }) {
			edges {
				node {
					slug
					title
					id
				}
			}
		}
	}
`;

export default OnDemandCategory;
